import styles from './Veterinarians.module.css'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import recordingPetImage from 'assets/images/screenshots/AppPreview5.png'
import resultNavigatorImage from 'assets/images/screenshots/AppPreview2.png'
import appStoreIcon from 'assets/images/logos/app_store_icon.png'
import playStoreIcon from 'assets/images/logos/play_store_icon.svg'
import { faBrain, faChartLineDown, faSackDollar, faShieldDog, faUmbrellaBeach, faUser } from '@fortawesome/pro-duotone-svg-icons'

const Veterinarians = () => {
    const navigate = useNavigate()

    const goToContactUs = () => {
        navigate('/contact-us')
    }

    const goToAppStore = () => {
        window.open('https://apps.apple.com/us/app/phauna/id6499479220', '_blank')
    }

    const goToPlayStore = () => {
        window.open('https://play.google.com/store/apps/details?id=com.phauna.phauna', '_blank')
    }

    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <h1 className={styles.title}>The New Age of Veterinary Telemedicine</h1>
                <p className={styles.tagline}>Complete high-quality cases in minutes from your smartphone</p>
                <button
                    className={styles.button}
                    onClick={goToContactUs}
                >
                    Start Earning
                </button>
                <div className={styles.appStoreContainer}>
                            {/* <p>Or download at</p> */}
                <button
                    className={styles.appStoreButton}
                    onClick={goToAppStore}
                >
                    <img
                        className={styles.appStoreIcon}
                        src={appStoreIcon}
                        alt="App Store Icon"
                    />
                    {/* <p>App Store</p> */}
                </button>
                <button
                    className={styles.appStoreButton}
                    onClick={goToPlayStore}
                >
                    <img
                        className={styles.appStoreIcon}
                        src={playStoreIcon}
                        alt="Play Store Icon"
                    />
                </button>
            </div>
            </div>
           
            {/* <hr className={styles.divider} /> */}
            {/* <div className={styles.screenshotContainer}>
                <img className={styles.screenshotImage} src={recordingPetImage} alt="Phauna\'s main page"/>
                <img className={styles.screenshotImage} src={resultNavigatorImage} alt="Phauna\'s main page"/>
                <img className={styles.screenshotImage} src={resultNavigatorImage} alt="Phauna\'s main page"/>
                <img className={styles.screenshotImage} src={resultNavigatorImage} alt="Phauna\'s main page"/>
            </div> */}
            <h2 style={{textAlign: 'center'}}>We're the best.</h2>
            <div className={styles.coreValuesContainer}>
                <div className={styles.valueContainer}>
                    <FontAwesomeIcon icon={faSackDollar} className={styles.valueIcon}/>
                    <h3 className={styles.valueTitle}>Earn More</h3>
                    <p className={styles.valueDescription}>Complete even complicated cases within minutes</p>
                </div>
                <div className={styles.valueContainer}>
                    <FontAwesomeIcon icon={faBrain} className={styles.valueIcon}/>
                    <h3 className={styles.valueTitle}>Understand Better</h3>
                    <p className={styles.valueDescription}>Recieve a comprehensive assessment and plan immediately</p>
                </div>
                <div className={styles.valueContainer}>
                    <FontAwesomeIcon icon={faUmbrellaBeach} className={styles.valueIcon}/>
                    <h3 className={styles.valueTitle}>Relax More</h3>
                    <p className={styles.valueDescription}>Operate on your own time from anywhere with your smartphone</p>
                </div>
            </div>
            <button
                className={styles.button}
                style={{marginBottom: '60px', marginTop: '30px'}}
                onClick={goToContactUs}
            >
                Start Earning
            </button>
        </div>
    )
}

export default Veterinarians