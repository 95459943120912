import styles from './HowItWorksStep.module.css'

interface Step {
    title: string
    description: string
    image: string
}

const HowItWorksStep = ({title, description, image}: Step) => {
    return (
        <div className={styles.container}>
            <img
                className={styles.image}
                src={image}
            />
            <p className={styles.title}>
                {title}
            </p>
            <p className={styles.paragraph}>
                {description}
            </p>
        </div>
    )
}

export default HowItWorksStep