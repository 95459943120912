import styles from './Footer.module.css'
import { Link } from 'react-router-dom'

interface Props {
    setNavOpen: (openNav: boolean) => void
}

const Footer = ({ setNavOpen }: Props) => {
    const currentYear = new Date().getFullYear();

    const goToTermsOfService = () => {
        setNavOpen(false)
        window.open('https://app.phauna.io/#/terms-of-service', '_blank')
    }

    const goToPrivacyPolicy = () => {
        setNavOpen(false)
        window.open('https://app.phauna.io/#/privacy-policy', '_blank')
    }

    return (
        <footer className={styles.footer}>
            <p className={styles.year}>Copyright &copy; {currentYear} Phauna</p>
            <p style={{color: 'rgba(255, 255, 255, 0.75)'}}>|</p>
            <Link
                className={styles.link}
                onClick={goToTermsOfService}
                to='/terms-of-service'
            >
                Terms of Service
            </Link>
            <p style={{color: 'rgba(255, 255, 255, 0.75)'}}>|</p>
            <Link
                className={styles.link}
                onClick={goToPrivacyPolicy}
                to='/privacy-policy'
            >
                Privacy Policy
            </Link>
            <div className={styles.links}>
            </div>
        </footer>
    )
}

export default Footer