
import styles from './HowItWorks.module.css'
import HowItWorksStep from './components/HowItWorksStep/HowItWorksStep'
import step1 from 'assets/images/screenshots/AppPreview1.png'
import step4 from 'assets/images/screenshots/AppPreview2.png'
import step6 from 'assets/images/screenshots/AppPreview3.png'
import step5 from 'assets/images/screenshots/AppPreview4.png'
import step2 from 'assets/images/screenshots/AppPreview5.png'
import step3 from 'assets/images/screenshots/AppPreview6.png'
import step7 from 'assets/images/screenshots/AppPreview7.png'
import appStoreIcon from 'assets/images/logos/app_store_icon.png'
import playStoreIcon from 'assets/images/logos/play_store_icon.svg'
import { Fragment } from 'react'
import { faBrowser } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


interface Step {
    id: number
    title: string
    description: string
    image: string
}

const HowItWorks = () => {
    const steps: Step[] = [
        {
            id: 1,
            title: 'Step 1',
            description: 'Add Your Concern(s)',
            image: step1
        },
        {
            id: 2,
            title: 'Step 2',
            description: 'Provide Relevant History',
            image: step3
        },
        {
            id: 3,
            title: 'Step 3',
            description: 'Provide Relevant Videos',
            image: step2
        },
        {
            id: 4,
            title: 'Step 4',
            description: 'Phauna Analyzes Your Information',
            image: step5
        },
        {
            id: 5,
            title: 'Step 5',
            description: 'Receive a Free Value Assessment',
            image: step4
        },
        {
            id: 6,
            title: 'Step 6',
            description: 'Recieve a Complete Assessment & Plan by a Vet',
            image: step5
        },
    ]

    const goToAppStore = () => {
        window.open('https://apps.apple.com/us/app/phauna/id6499479220', '_blank')
    }
    const goToPlayStore = () => {}
    const goToWebApp = () => {
        window.open('https://app.phauna.io', '_blank')
    }

    return (
        <div className={styles.container}>
            {/* <div className={styles.buttonContainer}>
                <button className={styles.button}>I am a veterinarian</button>
                <button className={styles.button}>I have a concern about my pet</button>
                <button className={styles.button}>I want to maximize Phauna's ability to help my pet</button>
            </div> */}
            <h1 className={styles.title}>How It Works</h1>
            {/* <h2>Available on</h2> */}
            {/* <div className={styles.appStoreContainer}>
                <button
                    className={styles.appStoreButton}
                    onClick={goToAppStore}
                >
                    <div>
                        <img
                            className={styles.appStoreIcon}
                            src={appStoreIcon}
                            alt="App Store Icon"
                        />
                        <p>
                            iOS
                        </p>
                    </div>
                </button>
                <button
                    className={styles.appStoreButton}
                    onClick={goToPlayStore}
                >
                    <div>
                        <img
                            className={styles.appStoreIcon}
                            src={playStoreIcon}
                            alt="Play Store Icon"
                        />
                        <p>
                            Android
                        </p>
                    </div>
                </button>
                <button
                    className={styles.appStoreButton}
                    onClick={goToPlayStore}
                >
                    <div>
                        <FontAwesomeIcon icon={faBrowser} style={{color: 'white', fontSize: '3.5rem'}}/>
                        <p>
                            Web
                        </p>
                    </div>
                </button>
            </div> */}
            <div className={styles.stepContainer}>
                {steps.slice(0, 3).map((step, index) => (
                    <Fragment key={index}>
                        <HowItWorksStep
                            key={index}
                            title={step.title}
                            description={step.description}
                            image={step.image}
                        />
                        {/* {index !== steps.length - 1 && <hr style={{width: '85%'}}/>} */}
                    </Fragment>
                ))}
            </div>
            <div className={styles.stepContainer}>
                {steps.slice(3, steps.length).map((step, index) => (
                    <Fragment key={index}>
                        <HowItWorksStep
                            key={index}
                            title={step.title}
                            description={step.description}
                            image={step.image}
                        />
                        {/* {index !== steps.length - 1 && <hr style={{width: '85%'}}/>} */}
                    </Fragment>
                ))}
            </div>
        </div>
    )
}

export default HowItWorks