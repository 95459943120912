import styles from './Home2.module.css'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import recordingPetImage from 'assets/images/screenshots/AppPreview5.png'
import resultNavigatorImage from 'assets/images/screenshots/AppPreview2.png'
import appStoreIcon from 'assets/images/logos/app_store_icon.png'
import playStoreIcon from 'assets/images/logos/play_store_icon.svg'
import { faBrain, faChartLineDown, faMedkit, faMobile, faMobileIphone, faMoneyCheckDollar, faSackDollar, faShieldDog, faUmbrellaBeach, faUser } from '@fortawesome/pro-duotone-svg-icons'
import { useContext } from 'react'
import { GlobalContext } from 'common/context/GlobalContext'

const Home2 = () => {
    const navigate = useNavigate()
    const context = useContext(GlobalContext)

    const goToContactUs = () => {
        navigate('/contact-us')
    }

    const goToAppStore = () => {
        window.open('https://apps.apple.com/us/app/phauna/id6499479220', '_blank')
    }

    const goToPlayStore = () => {
        window.open('https://play.google.com/store/apps/details?id=com.phauna.phauna', '_blank')
    }

    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <h1 className={styles.title}>The New Age of Veterinary Telemedicine</h1>
                <p className={styles.tagline}>
                    {
                        context?.isVet
                        ? 'Complete high-quality cases in minutes from your smartphone'
                        : 'Unmatched digital veterinary care at your convenience'
                    }
                </p>
                <button
                    className={styles.button}
                    onClick={goToContactUs}
                >
                    {
                        context?.isVet
                        ? 'Start Earning'
                        : 'Get Started'
                    }
                </button>
                <div className={styles.appStoreContainer}>
                            {/* <p>Or download at</p> */}
                    <button
                        className={styles.appStoreButton}
                        onClick={goToAppStore}
                    >
                        <img
                            className={styles.appStoreIcon}
                            src={appStoreIcon}
                            alt="App Store Icon"
                        />
                        {/* <p>App Store</p> */}
                    </button>
                    <button
                        className={styles.appStoreButton}
                        onClick={goToPlayStore}
                    >
                        <img
                            className={styles.appStoreIcon}
                            src={playStoreIcon}
                            alt="Play Store Icon"
                        />
                    </button>
                </div>
            </div>
           
            {/* <hr className={styles.divider} /> */}
            {/* <div className={styles.screenshotContainer}>
                <img className={styles.screenshotImage} src={recordingPetImage} alt="Phauna\'s main page"/>
                <img className={styles.screenshotImage} src={resultNavigatorImage} alt="Phauna\'s main page"/>
                <img className={styles.screenshotImage} src={resultNavigatorImage} alt="Phauna\'s main page"/>
                <img className={styles.screenshotImage} src={resultNavigatorImage} alt="Phauna\'s main page"/>
            </div> */}
            <h2 style={{textAlign: 'center'}}>We're the best.</h2>
            <div className={styles.coreValuesContainer}>
                <div className={styles.valueContainer}>
                    <FontAwesomeIcon icon={context?.isVet ? faSackDollar : faMedkit} className={styles.valueIcon}/>
                    <h3 className={styles.valueTitle}>
                        {
                            context?.isVet
                            ? 'Earn More'
                            : (
                                <>
                                    Unmatched<br />Care
                                </>
                            )
                        }
                    </h3>
                    <p className={styles.valueDescription}>
                        {
                            context?.isVet
                            ? 'Complete even complicated cases within minutes'
                            : 'Phauna is data-driven for unmatched diagnosis speed and accurancy'
                        }
                    </p>
                </div>
                <div className={styles.valueContainer}>
                    <FontAwesomeIcon icon={context?.isVet ? faBrain : faMobileIphone} className={styles.valueIcon}/>
                    <h3 className={styles.valueTitle}>
                        {
                            context?.isVet
                            ? 'Understand Better'
                            : (
                                <>
                                    Unmatched<br />Convenience
                                </>
                            )
                        }
                    </h3>
                    <p className={styles.valueDescription}>
                        {
                            context?.isVet
                            ? 'Recieve a comprehensive assessment and plan immediately'
                            : 'Phauna is fully at your own convenience unlike phone and video calls'
                        }
                    </p>
                </div>
                <div className={styles.valueContainer}>
                    <FontAwesomeIcon icon={context?.isVet ? faUmbrellaBeach : faMoneyCheckDollar} className={styles.valueIcon}/>
                    <h3 className={styles.valueTitle}>
                        {
                            context?.isVet
                            ? 'Relax More'
                            : (
                                <>
                                    Unmatched<br />Value
                                </>
                            )
                        }
                    </h3>
                    <p className={styles.valueDescription}>
                        {
                            context?.isVet
                            ? 'Operate on your own time from anywhere with your smartphone'
                            : 'Avoid unrewarding telemedicine visits with our free value predictor'
                        }
                    </p>
                </div>
            </div>
            <button
                className={styles.button}
                style={{marginBottom: '60px', marginTop: '30px'}}
                onClick={goToContactUs}
            >
                Start Earning
            </button>
        </div>
    )
}

export default Home2